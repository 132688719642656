import React, {useContext, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {
    addNewItemToOrder,
    changeComment,
    changeOrderDelivery,
    changeOrderPayment,
    changeOrderStatus,
    changeOrderUserFields,
    fetchChildProductsForOrder,
    getAllOrderStatus,
    getAllPayments,
    getOneOrder, refoundDolyami, refoundDolyamiCh, refoundCP, refoundCPCh,
    saveChangeDeliveryAddressFields,
    saveChangesOrderBasketItems,
    saveOrderCity,
    searchProductsBySearchValue,
    yandexMethodFind,
    refoundYSplit,
    refoundPartYSplit
} from "../../../../http/orderApi";
import './oneOrderPage.css';
import {priceToUserString} from "../../../../http/basketApi";
import {login} from "../../../../http/userAPI";
import {fetchAllDeliveryMethods} from "../../../../http/settingsApi";
import {Context} from "../../../../index";
import MiniCountController from "../../UI/miniCountController/MiniCountController";
import TrashBasket from "../../../../components/UI/trashBasket/TrashBasket";

const OneOrderPage = () => {

    const {user} = useContext(Context)


    const params = useParams();

    const [oneOrder, setOneOrder] = useState(null)
    const [createdAtOneOrder, setCreatedAtOneOrder] = useState(null)
    const [originalBasketItems, setOriginalBasketItems] = useState(null)
    const [basketItemsForOneOrder, setBasketItemsForOneOrder] = useState(null);
    const [cityInfoOneOrder, setCityInfoOneOrder] = useState(null);
    const [managerCommentsArr, setManagerCommentsArr] = useState([]);
    const [openManagerComments, setOpenMenagerComments] = useState(false)

    const [deliveryOrderFields, setDeliveryOrderFields] = useState(false)


    const [allStatusToOrder, setAllStatusToOrder] = useState(null);

   //##Сохранение статуса и строка оповещения.
    const [saveStatusTriggerButton, setSaveStatusTriggerButton] = useState(false);
    const [saveStatusNotification, setSaveStatusNotification] = useState("")

    //##Сохранение способа оплаты и строка оповещения.

    const [allPaymentMethods, setAllPaymentMethods] = useState(null);

    const [savePaymentTriggerButton, setSavePaymentTriggerButton] = useState(false);
    const [savePaymentNotification, setSavePaymentNotification] = useState("");

    //####### изменение способов доставки.
    const [allDeliveryMethods, setAllDeliveryMethods] = useState(null);

    const [saveDeliveryTriggerButton, setSaveDeliveryTriggerButton] = useState(false);
    const [saveDeliveryNotification, setsaveDeliveryNotification] = useState("");


    const [saveDeliveryAddressFieldsTrigger, setSaveDeliveryAddressFieldsTrigger] = useState(false);
    const [saveStatusAddressFields, setSaveStatusAddressFields] = useState("");

    const [infoClientTrigger,setInfoClientTrigger] = useState(false);
    const [infoClientSaveNotification, SetInfoClientSaveNotification] = useState("");

    //#### Универсальная функция позволяющая изменять поля по их имени.

    const [othersChildProductsForOrder,setOthersChildProductsForOrder] = useState(null);
    const [dolyamiContent, setDolyamiContent] = useState(null);
    const [returnedDolyami,setReturnedDolyami] = useState([]);
    const [ySplitContent, setYSplitContent] = useState(null);
    const [returnedYSplit,setReturnedYSplit] = useState([]);
    const [refundCPNotification, setrefundCPNotification] = useState([]);


    const changeField = (value,fieldName) =>  {
        setOneOrder({...oneOrder, [fieldName]:value})
    }

    const addNewItemToReturnDolyami = (object,index) => {
        let reduceItemsDolyami = [...returnedDolyami];
        let check = true;

        if(!reduceItemsDolyami.length){
            reduceItemsDolyami.push({...object,oldIndex:index});
            setReturnedDolyami(reduceItemsDolyami);
            return false;
        }

        reduceItemsDolyami.forEach((item) => {
            if(item.name === object.name && item.price === object.price){
                check = false;
            }
        })

        if(check) {
            reduceItemsDolyami.push({...object,oldIndex:index});
            setReturnedDolyami(reduceItemsDolyami);
        }
    }

    const addNewItemToReturnYSplit = (object,index) => {
        let reduceItemsYSplit = [...returnedYSplit];
        let check = true;

        if(!reduceItemsYSplit.length){
            reduceItemsYSplit.push({...object,oldIndex:index});
            setReturnedYSplit(reduceItemsYSplit);
            return false;
        }

        reduceItemsYSplit.forEach((item) => {
            if(item.title === object.title && item.total === object.total){
                check = false;
            }
        })

        if(check) {
            reduceItemsYSplit.push({...object,oldIndex:index});
            setReturnedYSplit(reduceItemsYSplit);
        }
    }


    const  saveUserOrderFields = (currentOrder) => {
        changeOrderUserFields(currentOrder).then(response => {
            if(response.error) {
                SetInfoClientSaveNotification(response.error);
                return false;
            }

            if(response.message) {
                SetInfoClientSaveNotification(response.message);
                setInfoClientTrigger(false);
                setTimeout(() => {
                    SetInfoClientSaveNotification("");
                },3000)
                return false;
            }
        })
    }


    const saveDeliveryAddressFields = (order) => {

        saveChangeDeliveryAddressFields(order).then(response => {
            if(response.error) {
                setSaveStatusAddressFields(response.error)
            }
            if(response.message) {
                setSaveStatusAddressFields(response.message);
                setSaveDeliveryAddressFieldsTrigger(false);
                setTimeout(()=> {
                    setSaveStatusAddressFields("")
                },2000)
            }
        })
    }


    useEffect(() => {
        // console.log(params)
        if (params.custom_order_id) {
            getOneOrder(params.custom_order_id).then(response => {

                if(response.error) {
                    return false;
                }

                if(response.dolyami_json_items){
                    console.log(response.dolyami_json_items)
                    setDolyamiContent(response.dolyami_json_items)
                }

                yandexMethodFind(+params.custom_order_id).then(response => {
                    console.log('response', response);
                    setYSplitContent(response.response);
                });

                // setDolyamiContent

                setOneOrder(response);
                setDeliveryOrderFields(JSON.parse(response.deliveryOrderFields))

                let data = new Date(response.createdAt);
                setCreatedAtOneOrder(data);
                if(response.cityInfo[0]){
                    setCityInfoOneOrder(JSON.parse(response.cityInfo))
                    // console.log(JSON.parse(response.cityInfo))
                } else {
                    setCityInfoOneOrder({value:""});
                }

                // console.log("BAAAAASKER ORDER")
                // console.log(JSON.parse(response.BasketItems))
                setOriginalBasketItems(JSON.parse(response.BasketItems))
                setBasketItemsForOneOrder(JSON.parse(response.BasketItems))
                setManagerCommentsArr(response.manager_comment)
                console.log(basketItemsForOneOrder)
                let arrayOfChildProductId = [];
                 JSON.parse(response.BasketItems).forEach((item,index) => {
                    arrayOfChildProductId.push(item.item.id);
                })
                // console.log("arrayOfChildProductId")
                // console.log(arrayOfChildProductId)
                fetchChildProductsForOrder(arrayOfChildProductId).then(response => {
                    setOthersChildProductsForOrder(response)
                    // console.log("reservItems");
                    // console.log(response);
                })

            })
            getAllOrderStatus().then(response => {
                if (response.error) {
                    return false;
                }
                setAllStatusToOrder(response)
            })
            getAllPayments().then(response => {
                if (response.error) {
                    return false;
                }

                setAllPaymentMethods(response)
            })

            fetchAllDeliveryMethods().then(response => {
                setAllDeliveryMethods(response)
            })
        }



        //

        //

    }, [])






    //#####Смена статуса заказа####

    const changeStatus = (item) => {
        // console.log(item)
        setOneOrder({...oneOrder, statusOrderId:item.id, status_order:item})
        setSaveStatusTriggerButton(true);
        // console.log(oneOrder)
    }
    const saveStatus = (orderId, statusOrderId) => {

        changeOrderStatus(orderId,statusOrderId).then(response => {
            if(response.error) {
                setSaveStatusNotification(response.error)
            }
            if(response.message) {
                setSaveStatusNotification(response.message);
                setSaveStatusTriggerButton(false);
                setTimeout(()=> {
                    setSaveStatusNotification("")
                },2000)
            }
        })
    }
    //#########Изменения способа оплаты

    const changePayment = (item) => {
        // console.log(item)
        // console.log(item)
        setOneOrder({...oneOrder, paymentmethodId:item.id, paymentmethod:item})
        setSavePaymentTriggerButton(true);

    }
    const savePayment = (orderId, paymentId) => {

        changeOrderPayment(orderId,paymentId).then(response => {
            if(response.error) {
                setSavePaymentNotification(response.error)
            }
            if(response.message) {
                setSavePaymentNotification(response.message);
                setSavePaymentTriggerButton(false);
                setTimeout(()=> {
                    setSavePaymentNotification("")
                },2000)
            }
        })
    }

    //#####Смена способа доставки
    const changeDelivery = (item) => {
        setOneOrder({...oneOrder, deliveryId:item.id, delivery:item})
        setSaveDeliveryTriggerButton(true);
    }

    const saveChangeDelivery = (orderId,deliveryId) => {

        changeOrderDelivery(orderId,deliveryId).then(response => {
            // console.log(response)
            if(response.error) {
                setsaveDeliveryNotification(response.error)
            }
            if(response.message) {
                setsaveDeliveryNotification(response.message);
                setSaveDeliveryTriggerButton(false);
                setTimeout(()=> {
                    setsaveDeliveryNotification("")
                },2000)
            }
        })
    }


    ////##### Добавление комментария

    const [commentField,setCommentField] = useState("");

    const deleteOneComment = (index) => {
        let currentComments = [...managerCommentsArr];
        currentComments.splice(index,1);

        changeComment(oneOrder.custom_order_id, currentComments).then(response => {
            if(response.message) {

                setManagerCommentsArr(currentComments)
                setCommentField('');

            }
        })
    }

    const addNewCommentHandler = () => {
        if(!commentField) {
            return false;
        }
       let currentComments = managerCommentsArr;
       currentComments.push({text:commentField, user_name:(user.user.name ? user.user.name : user.user.login), id:user.user.id, time:new Date().toLocaleString()});
        changeComment(oneOrder.custom_order_id, currentComments).then(response => {
            // console.log(response)
            if(response.message) {
                setCommentField('');
                setManagerCommentsArr(currentComments)
            }
        })

    }

    const commentChangeHandler = (value) => {
        setCommentField(value)
        // addNewCommentHandler()
    }

//////////////////////
    const [chgDelivInfoTrig,setChgDelivInfoTrig] = useState(false)

    const customChangeCity = (value) => {
        setCityInfoOneOrder({...cityInfoOneOrder, value:value})
        setChgDelivInfoTrig(true)
    }
    /// Сохранение города
    const [saveCityNitificator,setSaveCityNotificator] = useState("");
    const saveCityHandler = (orderId,object) => {
        saveOrderCity(orderId,object).then(response => {
            if(response.error) {
                setSaveCityNotificator(response.error);
                return false;
            }
            if(response.message){
                setSaveCityNotificator(response.message);
                setChgDelivInfoTrig(false)
                setTimeout(() => {
                    setSaveCityNotificator('')
                },3000)
                return false;
            }
        })
    }

    const changeMbCountOneItem = (param, index, mbIndex) => {

        let item = Array.isArray(basketItemsForOneOrder[index].mbPerformance[mbIndex]) ? basketItemsForOneOrder[index].mbPerformance[mbIndex] : basketItemsForOneOrder[index].mbPerformance
        let discountedPriceOfLine = item.discountedPriceOfLine;
        let quantity = item.quantity;

        if (param === "max") {
            if (item.mbPerformance && item.mbPerformance.length >= 1) {
                setBasketItemsForOneOrder(
                    [...basketItemsForOneOrder],
                    [
                        item.quantity
                            = quantity < item.quantity
                                ? quantity + 1 : quantity
                    ],
                    [
                        basketItemsForOneOrder[index].count
                            = quantity < item.quantity
                                ? basketItemsForOneOrder[index].count + 1 : basketItemsForOneOrder[index].count
                    ],
                    [
                        item.discountedPriceOfLine
                            = quantity < item.quantity
                                ? discountedPriceOfLine + discountedPriceOfLine : discountedPriceOfLine
                    ],
                );
            }
        } else if (param === "min") {
            if (item.quantity === 1) {
                return false;
            }
            setBasketItemsForOneOrder(
                [...basketItemsForOneOrder],
                [
                    item.quantity
                        = quantity - 1
                ]
                [
                    basketItemsForOneOrder[index].count = basketItemsForOneOrder[index].count - 1
                ],
                [
                    item.discountedPriceOfLine
                        = discountedPriceOfLine - (discountedPriceOfLine / quantity)
                ],

            )
        }
    }

    const changeCountOneItem = (param,index) => {
        if(param === "max") {
            setBasketItemsForOneOrder(
                [...basketItemsForOneOrder],
                [
                    basketItemsForOneOrder[index].count =
                        basketItemsForOneOrder[index].count < originalBasketItems[index].count
                            ? basketItemsForOneOrder[index].count + 1 : basketItemsForOneOrder[index].count
                ]
            );
        }
        if(param === "min") {
            if(basketItemsForOneOrder[index].count === 1) {
                return false;
            }
            setBasketItemsForOneOrder([...basketItemsForOneOrder], [basketItemsForOneOrder[index].count = basketItemsForOneOrder[index].count-1])

        }
    }

    const deleteOneItemBasket = (index) => {
        let reduceBasketItems = [...basketItemsForOneOrder];
        reduceBasketItems.splice(index,1);
        setBasketItemsForOneOrder(reduceBasketItems)
    }

    const deleteOneMbItemBasket = (index, mbIndex) => {
        const anotherLine = basketItemsForOneOrder[index].mbPerformance.filter((perf, j) => j !== mbIndex);
        if (anotherLine.length === 0) return false;

        setBasketItemsForOneOrder(
            [...basketItemsForOneOrder],
            [basketItemsForOneOrder[index].count = anotherLine[0].quantity]
        );
        setBasketItemsForOneOrder(prevItems => {
            return prevItems.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        mbPerformance: item.mbPerformance.filter((perf, j) => j !== mbIndex)
                    };
                }

                return item;
            });
        });
    }

    const changeSizeOneOrderItemHandler = (newSize,index) => {
        setBasketItemsForOneOrder([...basketItemsForOneOrder],[basketItemsForOneOrder[index].currentSize = newSize])
    }

    const oneProductSwitchColor = (newChildItem,indexBasketItem, newParrentItem) => {
        // console.log(newItem)
        // console.log("START ITEMS BASKET - ЧТО ИМЕЕМ В НАЧАЛЕ")
        // console.log(basketItemsForOneOrder)
        // console.log("СОДЕРЖИТ В СЕБЕ ВСЕ ТОРГОВЫЕ ПРЕДЛОЖЕНИЯ НОВЫЕ")
        // console.log(newParrentItem)
        // console.log("CHILD НА КОТОРЫЙ КЛИКНУЛИ")
        // console.log(newChildItem)



        let reduceBasketItems = [...basketItemsForOneOrder];
        //
        let product = {...newParrentItem.product};
        let newItem = {...newChildItem, product: product};
        //
        reduceBasketItems[indexBasketItem].item = newItem;
        reduceBasketItems[indexBasketItem].colorId = newItem.color.id;

        /// Ищем наличие размера в активном торговом предл. чтобы переключить, если его нет
        let isExistSize = false;
        newChildItem.child_product_size_field.forEach((item,index) => {
            if(item.size_name === reduceBasketItems[indexBasketItem].currentSize) {
                isExistSize = true;
            }
        })
        if(!isExistSize) {
           reduceBasketItems[indexBasketItem].currentSize = newChildItem.child_product_size_field[0].size_name;
        }




        setBasketItemsForOneOrder(reduceBasketItems);
    }



    const [activeAreaAddNewProduct,setActiveAreaAddNewProduct] = useState(false);

    const [searchValueNewProduct,setSearchValueNewProduct] = useState("");

    const [searchProductsResult, setSearchProductsResult] = useState(null);


    useEffect(() => {
        if(searchValueNewProduct.length < 3) {
            setSearchProductsResult(null)
            return false;
        }
        searchProductsBySearchValue(searchValueNewProduct).then(response => {
            // console.log(response)
            if(response.error) {
                setSearchProductsResult(null)
                return false;
            }

            setSearchProductsResult(response)


        })

    },[searchValueNewProduct])

    const [selectedOneProductInSearchArea,setSelectedOneProductInSearchArea] = useState(null)
    const [selectedChild,setSelectedChild] = useState(null)
    const [selectedCurrentSize,setSelectedCurrentSize] = useState("");
    const [selectedCount,setSelectedCount] = useState(1);

    const changeSelectedCountHandler = (param,value) => {
        if(param === "min" && value === 1) {
            return false;
        }

        if(param === "min"){
            setSelectedCount(selectedCount-1);
            return false;
        }
        if(param === "max"){
            setSelectedCount(selectedCount+1);
            return false;
        }

    }

    const searchAreaOneItemClickHandler = (item) => {
        if(!item) {
            return false;
        }
        // console.log(item)
        // console.log("dsadasdadas")
        setSelectedOneProductInSearchArea(item);
        setSelectedChild(item.childProducts[0]);
        setSelectedCurrentSize(item.childProducts[0].child_product_size_field[0].size_name);

    }

    const switchSelectedChild = (item) => {
        setSelectedChild(item);
        setSelectedCurrentSize(item.child_product_size_field[0].size_name);
    }
    const goToBack = () => {
        setSelectedOneProductInSearchArea(null);
        setSelectedChild(null);
        setSelectedCount(1)
    }

    const addSelectedItemToOrderBasket = () => {

        if(!selectedChild) {
            return false;
        }
        if(!selectedCount) {
            return false;
        }
        if(!setSelectedCurrentSize) {
            return false;
        }
        const itemAddToOrder = {item: selectedChild, count:selectedCount, currentSize:selectedCurrentSize,colorId:selectedChild.color.id, child_product_id:selectedChild.id}
        addNewItemToOrder(itemAddToOrder,oneOrder.id).then(response => {
            // console.log(response);
            if(response.message) {
                window.location.reload();
            }
        })

    }

    const saveChangesBasketItemsOrdedById = (basketItems,orderId) => {
        saveChangesOrderBasketItems(basketItems,orderId).then(response => {
            if(response.message) {
                window.location.reload();
            }
            //
        })
    }

    const refoundHandler = (order,items) => {
        refoundDolyami(order,items).then(response => {
            console.log(response)
            if(response.message) {
                window.location.reload();
            }
        })
    }

    const refoundYSplitHandler = (order,items) => {
        refoundYSplit(order,items).then(response => {
            if(response.success) {
                window.location.reload();
            }
        })
    }

    const changeCountItems = (index, val) => {

        let reduceItems = [...returnedDolyami];
        setReturnedDolyami([...returnedDolyami] , [returnedDolyami[index].quantity = val])

    }

    const changeRefundYSplitCountItems = (index, val) => {

        let reduceItems = [...returnedYSplit];
        setReturnedYSplit([...returnedYSplit] , [returnedYSplit[index].quantity.count = val])

    }

    const refoundDolyamiChHandler = (order,items) => {
        refoundDolyamiCh(order,items).then(response => {
            console.log(response)
            window.location.reload()
        })
    }

    const refoundPartYSplitHandler = (order,items) => {
        refoundPartYSplit(order,items).then(response => {
            console.log(response)
            window.location.reload()
        })
    }

    const refoundCPChHandler = (order,items) => {
        refoundCPCh(order,items).then(response => {
            if(response.error) {
                setrefundCPNotification(response.error)
            }
            if(response.message) {
                window.location.reload();
            }
        })
    }

    const refoundCPHandler = (order) => {
        refoundCP(order).then(response => {
            console.log(response)
            if(response.error) {
                setrefundCPNotification(response.error)
            }
            if(response.message) {
                window.location.reload();
            }
        })
    }

    const deleteRefoundItemList = (index_stat) => {
        let reduceItems = [...returnedDolyami];
        let newReturnedItems = [];
        reduceItems.forEach((item,index) => {
            if(index_stat !== index) {
                newReturnedItems.push(item)
            }
        })
        setReturnedDolyami(newReturnedItems);
    }

    const deleteRefoundYSplitItemList = (index_stat) => {
        let reduceItems = [...returnedYSplit];
        let newReturnedItems = [];
        reduceItems.forEach((item,index) => {
            if(index_stat !== index) {
                newReturnedItems.push(item)
            }
        })
        setReturnedYSplit(newReturnedItems);
    }
    // console.log(oneOrder)
    return (
        <div className={"one_order_page_wrapper"}>
            <div className={"one_order_page_sub_wrapper"}>
                {
                    oneOrder ?
                    <div className="one_order_page_content_container">
                        <div className={"one_order_page_top_line_for_inform"}>
                            <div className={"one_order_page_top_line_left"}>
                                Просмотр / редактирование заказа № {oneOrder.order_number}, ID: {oneOrder.custom_order_id}
                            </div>
                            <div className={"one_order_page_top_line_right"}>
                                Создан - {createdAtOneOrder ? createdAtOneOrder.toLocaleString() : ""}
                            </div>
                        </div>
                        {
                            oneOrder.uuid ?
                            <div className={"one_order_page_uuid_line_for_inform"}>
                                Imshop uuid = {oneOrder.uuid}
                            </div>
                            : ""
                        }

                        <div className={"one_order_page_ident_container"}>
                            <div className="one_order_page_ident_container_top_line">
                                <div className="one_order_page_ident_container_top_line_item_left">
                                    Основные данные заказа
                                </div>
                                <div className="one_order_page_ident_container_top_line_item_right">
                                    <span className={"one_order_page_status_indicator"}>{"Статус: "+oneOrder.status_order.name}</span>
                                </div>
                            </div>
                            <div className={"one_order_page_data_wrapper"}>
                                <div className={"one_order_page_data_one_item"}>
                                    <div className="one_order_page_data_one_item_heading">
                                        Информация о клиенте из заказа
                                    </div>
                                    <div className={"one_order_page_data_one_item_params"}>
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Имя</span>
                                            <input
                                                value={oneOrder.name ? oneOrder.name : ""}
                                                onChange={e => ( changeField(e.target.value,"name"), setInfoClientTrigger(true))}
                                                type="text"/>
                                        </div>
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Фамилия</span>
                                            <input
                                                value={oneOrder.surname ? oneOrder.surname : ""}
                                                onChange={e => (changeField(e.target.value,"surname", setInfoClientTrigger(true)))}

                                                type="text"/>
                                        </div>
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Отчество</span>
                                            <input
                                                value={oneOrder.patronymic ? oneOrder.patronymic : ""}
                                                onChange={e => (changeField(e.target.value,"patronymic",setInfoClientTrigger(true)))}
                                                type="text"/>
                                        </div>
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Email</span>
                                            <input
                                                value={oneOrder.email ? oneOrder.email : ""}
                                                onChange={e => (changeField(e.target.value,"email"),setInfoClientTrigger(true))}
                                                type="text"/>
                                        </div>
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Телефон</span>
                                            <input
                                                value={oneOrder.telephone ? oneOrder.telephone : ""}
                                                onChange={e => (changeField(e.target.value,"telephone",setInfoClientTrigger(true)))}
                                                type="text"/>
                                        </div>
                                        <div className={"one_order_one_block_response"}>{infoClientSaveNotification ? infoClientSaveNotification : ""}</div>
                                        <span
                                            onClick={e => saveUserOrderFields(oneOrder) }
                                            className={infoClientTrigger ? "save_change_status trig" : "save_change_status"}>Сохранить</span>
                                    </div>

                                </div>
                                <div className={"one_order_page_data_one_item"}>
                                    <div className="one_order_page_data_one_item_heading">
                                        Информация о клиенте из ЛК
                                    </div>

                                    {oneOrder.user  ?
                                        <div className={"one_order_page_data_one_item_params"}>
                                            <div className="one_order_field_container">
                                                <span className={"one_order_field_container_name"}>Имя</span>
                                                <span className={"one_order_field_container_value"}>{oneOrder.user.name ? oneOrder.user.name : ""}</span>
                                            </div>
                                            <div className="one_order_field_container">
                                                <span className={"one_order_field_container_name"}>Фамилия</span>
                                                <span className={"one_order_field_container_value"}>{oneOrder.user.surname ? oneOrder.user.surname : ""}</span>

                                            </div>
                                            <div className="one_order_field_container">
                                                <span className={"one_order_field_container_name"}>Отчество</span>
                                                <span className={"one_order_field_container_value"}>{oneOrder.user.patronymic ? oneOrder.user.patronymic : ""}</span>
                                            </div>
                                            <div className="one_order_field_container">
                                                <span className={"one_order_field_container_name"}>Email</span>
                                                <span className={"one_order_field_container_value"}>{oneOrder.user.email ? oneOrder.user.email : ""} </span>
                                            </div>
                                            <div className="one_order_field_container">
                                                <span className={"one_order_field_container_name"}>Телефон</span>
                                               <span className={"one_order_field_container_value"}>{oneOrder.user.telephone ? oneOrder.user.telephone : ""}</span>
                                            </div>
                                        </div>
                                     :
                                        <div className={"one_order_page_data_one_item_params center"} >
                                                                Нет данных
                                        </div>

                                    }


                                </div>
                                <div className={"one_order_page_data_one_item"}>
                                    <div className="one_order_page_data_one_item_heading">
                                        Информация о стоимости
                                    </div>
                                    <div className="one_order_page_data_one_item_params">
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Оплачено:</span>
                                            <span className={"one_order_field_container_value pay_indicator"}>{oneOrder.paymentAmount === oneOrder.result_price ? "Оплачено" : "Нет"}</span>
                                        </div>
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Оплачено:</span>
                                            <span className={"one_order_field_container_value"}>{priceToUserString(oneOrder.paymentAmount)+" ₽ из "+priceToUserString(oneOrder.result_price)+"  ₽"}</span>
                                        </div>
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>К оплате</span>
                                            <span className={"one_order_field_container_value"}>{priceToUserString(oneOrder.result_price)+"  ₽"}</span>
                                        </div>

                                        <span className={"one_order_page_custom_span"}>
                                            Детально
                                        </span>
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Без скидки</span>
                                            <span className={"one_order_field_container_value"}>{oneOrder.no_sale_summ ? priceToUserString(oneOrder.no_sale_summ)+"  ₽": ""}</span>
                                        </div>
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Скидка:</span>
                                            <span className={"one_order_field_container_value"}>
                                                {
                                                  oneOrder.no_sale_summ + oneOrder.deliveryPrice !== oneOrder.result_price ?
                                                      priceToUserString((oneOrder.no_sale_summ + oneOrder.deliveryPrice - oneOrder.result_price).toFixed(2))+"  ₽"
                                                      :
                                                      "0 ₽"
                                                // oneOrder.deliveryPrice ? priceToUserString(oneOrder.deliveryPrice)+"  ₽" : "0 ₽"
                                                }
                                            </span>
                                        </div>
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Доставка:</span>
                                            <span className={"one_order_field_container_value"}>{oneOrder.deliveryPrice ? priceToUserString(oneOrder.deliveryPrice)+"  ₽" : "0 ₽"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"one_order_page_data_one_item"}>
                                    <div className="one_order_page_data_one_item_heading">
                                        Информация о доставке
                                    </div>
                                    <div className="one_order_page_data_one_item_params">
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Доставка:</span>
                                            <span className={"one_order_field_container_value"}>{oneOrder.delivery ? oneOrder.delivery.name : "Не выбрано"}</span>
                                        </div>
                                        <div className="one_order_field_container">
                                            <span className={"one_order_field_container_name"}>Город</span>
                                            {
                                                cityInfoOneOrder ?
                                                    <input
                                                        value={cityInfoOneOrder.value ? cityInfoOneOrder.value  : ""}
                                                        onChange={e => customChangeCity(e.target.value)}
                                                        type="text"/>
                                                    : ""
                                            }

                                        </div>
                                        {
                                            oneOrder.delivery ?
                                                    <div>
                                                        {
                                                            oneOrder.delivery.delivery_type === "sdek_pvz" || oneOrder.delivery.delivery_type === "yandex_pvz" ?
                                                                <div>
                                                                    <div className="one_order_field_container">
                                                                        <span className={"one_order_field_container_name"}>Code:</span>
                                                                        <span className={"one_order_field_container_value"}>{oneOrder.pvz_code ? oneOrder.pvz_code : "" }</span>
                                                                    </div>
                                                                    <div className="one_order_field_container">
                                                                        <span className={"one_order_field_container_name"}>Postal:</span>
                                                                        <span className={"one_order_field_container_value"}>{oneOrder.pvz_postal ? oneOrder.pvz_postal : "" }</span>
                                                                    </div>
                                                                    <div className="one_order_field_container column">
                                                                        <span className={"one_order_field_container_name"}>Адресс:</span>
                                                                        <span className={"one_order_field_container_value"}>{oneOrder.pvz_address ? oneOrder.pvz_address : "" }</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <div className="one_order_field_container">
                                                                        <span className={"one_order_field_container_name"}>Адрес:</span>
                                                                        <span className={"one_order_field_container_value"}>{oneOrder.orderAdressSearch ? oneOrder.orderAdressSearch : "" }</span>
                                                                    </div>
                                                                    <div className="one_order_field_container">
                                                                        <span className={"one_order_field_container_name"}>Квартира:</span>
                                                                        <span className={"one_order_field_container_value"}>{deliveryOrderFields.flat ? deliveryOrderFields.flat : "" }</span>
                                                                    </div>
                                                                    <div className="one_order_field_container">
                                                                        <span className={"one_order_field_container_name"}>Этаж:</span>
                                                                        <span className={"one_order_field_container_value"}>{oneOrder.floor ? oneOrder.floor : "" }</span>
                                                                    </div>
                                                                    <div className="one_order_field_container">
                                                                        <span className={"one_order_field_container_name"}>Подьезд:</span>
                                                                        <span className={"one_order_field_container_value"}>{oneOrder.front_door ? oneOrder.front_door : "" }</span>
                                                                    </div>
                                                                    <div className="one_order_field_container">
                                                                        <span className={"one_order_field_container_name"}>Домофон:</span>
                                                                        <span className={"one_order_field_container_value"}>{oneOrder.intercom ? oneOrder.intercom : "" }</span>
                                                                    </div>
                                                                </div>

                                                        }

                                                    </div>
                                                :
                                                <div className={"one_order_page_no_data_block"}>Выберите доставку</div>
                                        }
                                        <div className={"one_order_one_block_response"}>{saveCityNitificator ? saveCityNitificator : ""}</div>

                                        <span
                                            onClick={e => saveCityHandler(oneOrder.custom_order_id, cityInfoOneOrder)}
                                            className={chgDelivInfoTrig ? "save_change_status trig" : "save_change_status"}>Сохранить</span>

                                    </div>


                                </div>
                                <div className={"one_order_page_data_one_item"}>
                                    <div className="one_order_page_data_one_item_heading">
                                        Выбранный способ оплаты
                                    </div>
                                    <div className="one_order_page_data_one_item_params">
                                        <div className="one_order_field_container column">
                                            <span className={"one_order_field_container_name no_fix"}>Способ оплаты:</span>
                                            <span className={"one_order_field_container_value"}>{oneOrder.paymentmethod.name ? oneOrder.paymentmethod.name : "" }</span>
                                        </div>
                                        <div className="one_order_field_container column">
                                            <span className={"one_order_field_container_name no_fix"}>Ссылка на оплату / заказ:</span>
                                            <span className={"one_order_field_container_value"}>{ process.env.REACT_APP_API_URL+"order/id/"+oneOrder.custom_order_id+"/" }</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {
                            oneOrder.paymentmethod.id == 4 ? (<div className="one_order_page_ident_container">
                                    <div className="one_order_page_ident_container_top_line">
                                        <div className="one_order_page_ident_container_top_line_item_left">
                                            Возврат средств Dolyami
                                        </div>
                                        <div className="one_order_page_ident_container_top_line_item_right">
                                            <span className={"one_order_page_status_indicator"}>{"Статус: "+ oneOrder.dolyami_status ? oneOrder.dolyami_status : ""}</span>
                                        </div>
                                    </div>
                                    <span>В заказе: </span>
                                    <div className={"wrapper_items_dolyami_order"}>
                                        {
                                            dolyamiContent ? dolyamiContent.map((item,index) => {
                                                return( <div key={index} className={"containder_for_return_items"}>
                                                        <span>Название: {item.name}</span>
                                                        <span>Количество: {item.quantity}</span>
                                                        <span>Цена за 1 шт: {item.price}</span>
                                                        <div onClick={e => addNewItemToReturnDolyami(Object.assign({}, item ), index)} className={"dolyami_add_to_return"}>Добавить</div>
                                                    </div>
                                                )
                                            }) : ""
                                        }
                                    </div>
                                    <span className={"one_order_page_ident_dop_info"}>Вещи для частичного возврата. Если тут пусто, нажмите кнопку "добавить" в списке выше. </span>
                                    <div className={"wrapper_items_dolyami_order"}>
                                        {
                                            returnedDolyami.length ? returnedDolyami.map((item,index) => {
                                                return( <div key={index} className={"containder_for_return_items"}>
                                                        <span>Название: {item.name}</span>
                                                        <span className={"span_doly_flex"}>Количество: <input className={"count_inp_dolyami"} value={item.quantity} onChange={ e => changeCountItems(index,e.target.value)}/> </span>
                                                        <span>Цена за 1 шт: {item.price}</span>
                                                        <div onClick={e => deleteRefoundItemList(index)} className={"dolyami_add_to_return"}>Удалить</div>
                                                    </div>
                                                )
                                            }) : ""
                                        }

                                    </div>
                                    {
                                        returnedDolyami.length ?
                                            <div onClick={e => refoundDolyamiChHandler(oneOrder, returnedDolyami)} className={"return_doly_items"}>Вернуть часть товаров</div>
                                            : ""
                                    }
                                    <div className={"one_order_page_ident_dop_info"}>
                                        Внимание! Для полного возврата средств и отмены заказа нажмите кнопку ниже.
                                    </div>
                                    <span onClick={e => refoundHandler(oneOrder,oneOrder.dolyami_json_items)} className={"dolyami_refound_money"}>
                                Полный возврат средств
                            </span>
                                </div>): ''
                        }

                        {
                            oneOrder.paymentmethod.code == 'ysplit' ? (
                                <div className="one_order_page_ident_container">
                                    <div className="one_order_page_ident_container_top_line">
                                        <div className="one_order_page_ident_container_top_line_item_left">
                                            Возврат средств YSplit Split
                                        </div>
                                        <div className="one_order_page_ident_container_top_line_item_right">
                                            <span className={"one_order_page_status_indicator"}>{"Статус: "+ ySplitContent?.status}</span>
                                        </div>
                                    </div>
                                    <span>В заказе: </span>
                                    <div className={"wrapper_items_dolyami_order"}>
                                        {
                                            ySplitContent?.status !== 'canceled' ? ySplitContent?.items?.map((item,index) => {
                                                return( <div key={index} className={"containder_for_return_items"}>
                                                        <span>Название: {item.title}</span>
                                                        <span>Количество: {item.quantity.count}</span>
                                                        <span>Цена за 1 шт: {item.discountedUnitPrice}</span>
                                                        <div onClick={e => addNewItemToReturnYSplit(Object.assign({}, item ), index)} className={"dolyami_add_to_return"}>Добавить</div>
                                                    </div>
                                                )
                                            }) : ""
                                        }
                                    </div>
                                    {
                                        ySplitContent?.status !== 'waiting for refund' ?
                                        <span className={"one_order_page_ident_dop_info"}>Вещи для частичного возврата. Если тут пусто, нажмите кнопку "добавить" в списке выше. </span>
                                        : ""
                                    }
                                    <div className={"wrapper_items_dolyami_order"}>
                                        {
                                            returnedYSplit.length ? returnedYSplit.map((item,index) => {
                                                return( <div key={index} className={"containder_for_return_items"}>
                                                        <span>Название: {item.title}</span>
                                                        <span className={"span_doly_flex"}>Количество: <input className={"count_inp_dolyami"} value={item.quantity.count} onChange={ e => changeRefundYSplitCountItems(index,e.target.value)}/> </span>
                                                        <span>Цена за 1 шт: {item.discountedUnitPrice}</span>
                                                        <div onClick={e => deleteRefoundYSplitItemList(index)} className={"dolyami_add_to_return"}>Удалить</div>
                                                    </div>
                                                )
                                            }) : ""
                                        }
                                    </div>
                                    {
                                        returnedYSplit.length && ySplitContent?.status !== 'waiting for refund' ?
                                            <div onClick={e => refoundPartYSplitHandler(oneOrder, returnedYSplit)} className={"return_doly_items"}>Вернуть часть товаров</div>
                                            : ""
                                    }
                                    {
                                        ySplitContent?.status !== 'waiting for refund' ?
                                        <div>
                                            <div className={"one_order_page_ident_dop_info"}>
                                                Внимание! Для полного возврата средств и отмены заказа нажмите кнопку ниже.
                                            </div>

                                            <span onClick={e => refoundYSplitHandler(oneOrder, ySplitContent)} className={"dolyami_refound_money"}>
                                                Полный возврат средств
                                            </span>
                                        </div>
                                            : ""
                                    }
                                </div>
                                )
                                : ''
                        }

                        {
                            oneOrder.paymentmethod.id == 1 ? (<div className="one_order_page_ident_container">
                                <div className="one_order_page_ident_container_top_line">
                                    <div className="one_order_page_ident_container_top_line_item_left">
                                        Возврат средств Cloud Payments
                                    </div>
                                </div>
                                <span>В заказе: </span>
                                <div className={"wrapper_items_dolyami_order"}>
                                    {
                                        basketItemsForOneOrder ? basketItemsForOneOrder.map((item,index) => {
                                            return( <div key={index} className={"containder_for_return_items"}>
                                                    <span>Название: {item.item.name}</span>
                                                    <span>Количество: {item.count}</span>
                                                    <span>Цена за 1 шт: {item.item.result_price}</span>
                                                    <div onClick={e => addNewItemToReturnDolyami({name: item.item.name, price: item.item.result_price, quantity: item.count} , index)} className={"dolyami_add_to_return"}>Добавить</div>
                                                </div>
                                            )
                                        }) : ""
                                    }
                                </div>
                                <span className={"one_order_page_ident_dop_info"}>Вещи для частичного возврата. Если тут пусто, нажмите кнопку "добавить" в списке выше. </span>
                                <div className={"wrapper_items_dolyami_order"}>
                                    {
                                        returnedDolyami.length ? returnedDolyami.map((item,index) => {
                                            return( <div key={index} className={"containder_for_return_items"}>
                                                    <span>Название: {item.name}</span>
                                                    <span className={"span_doly_flex"}>Количество: <input className={"count_inp_dolyami"} value={item.quantity} onChange={ e => changeCountItems(index,e.target.value)}/> </span>
                                                    <span>Цена за 1 шт: {item.price}</span>
                                                    <div onClick={e => deleteRefoundItemList(index)} className={"dolyami_add_to_return"}>Удалить</div>
                                                </div>
                                            )
                                        }) : ""
                                    }
                                </div>
                                {
                                    returnedDolyami.length ?
                                        <div onClick={e => refoundCPChHandler(oneOrder, returnedDolyami)} className={"return_doly_items"}>Вернуть часть товаров</div>
                                        : ""
                                }
                                <div className={"one_order_page_ident_dop_info"}>
                                    Внимание! Для полного возврата средств и отмены заказа нажмите кнопку ниже.
                                </div>
                                <span onClick={e => refoundCPHandler(oneOrder)} className={"dolyami_refound_money"}>
                                    Полный возврат средств
                                </span><span className={"notification_save_changer_line"}>{refundCPNotification ? refundCPNotification : ""}</span>
                            </div>): ''
                        }

                        <div className="one_order_page_ident_container">
                            <div className="one_order_page_ident_container_top_line">
                                <div className="one_order_page_ident_container_top_line_item_left">
                                    Статус заказа
                                </div>
                                <div className="one_order_page_ident_container_top_line_item_right">
                                    <span className={"one_order_page_status_indicator"}>{"Статус: "+oneOrder.status_order.name}</span>
                                </div>
                            </div>



                            <div className={"one_order_page_ident_dop_info"}>
                                Для изменения статуса заказа - выберите один из вариантов
                            </div>
                            <div className={"one_order_page_wrapper_for_all_status"}>
                                {
                                    allStatusToOrder ?
                                        allStatusToOrder.map((item, index) => {
                                            return (<div

                                                 key={item.id}
                                                className={"one_item_to_change_wrapper"} >

                                                        <div
                                                            onClick={e => changeStatus(item)}
                                                            className={"one_item_to_change_status"}>
                                                            {item.name}
                                                        </div>

                                            </div>)
                                        }) : ""
                                }
                            </div>
                            <div className={"one_order_page_save_and_notification_line"}>
                                <span
                                    onClick={e => saveStatus(oneOrder.custom_order_id, oneOrder.statusOrderId)}
                                    className={ saveStatusTriggerButton ? "save_change_status trig" : "save_change_status"}>Сохранить</span>

                                <span className={"notification_save_changer_line"}>{saveStatusNotification ? saveStatusNotification : ""}</span>

                            </div>

                        </div>
                        <div className="one_order_page_ident_container">
                            <div className="one_order_page_ident_container_top_line">
                                <div className="one_order_page_ident_container_top_line_item_left">
                                    Выбрана оплата
                                </div>
                                <div className="one_order_page_ident_container_top_line_item_right">
                                    <span className={"one_order_page_status_indicator"}>{oneOrder.paymentmethod.name}</span>
                                </div>
                            </div>
                            <div className={"one_order_page_ident_dop_info"}>
                                Для изменения способа оплаты заказа - выберите один из вариантов
                            </div>
                            <div className={"one_order_page_wrapper_for_all_status"}>

                                {
                                    allPaymentMethods ?
                                        allPaymentMethods.map((item, index) => {
                                            return (<div

                                                key={item.id}
                                                className={"one_item_to_change_wrapper"} >

                                                <div
                                                    onClick={e => changePayment(item)}
                                                    className={"one_item_to_change_status"}>
                                                    {item.name}
                                                </div>

                                            </div>)
                                        }) : ""
                                }
                            </div>
                            <div className={"one_order_page_save_and_notification_line"}>
                                <span
                                    onClick={e => savePayment(oneOrder.custom_order_id, oneOrder.paymentmethodId)}
                                    className={ savePaymentTriggerButton ? "save_change_status trig" : "save_change_status"}>Сохранить</span>

                                <span className={"notification_save_changer_line"}>{savePaymentNotification ? savePaymentNotification : ""}</span>

                            </div>

                        </div>



                        <div className="one_order_page_ident_container">
                            <div className="one_order_page_ident_container_top_line">
                                <div className="one_order_page_ident_container_top_line_item_left">
                                    Текущая доставка
                                </div>
                                <div className="one_order_page_ident_container_top_line_item_right">
                                    <span className={"one_order_page_status_indicator"}>{oneOrder.delivery ? oneOrder.delivery.name : "Не выбрано"}</span>
                                </div>
                            </div>
                            <div className={"one_order_page_ident_dop_info"}>
                                При выборе внимательно смотрите условия доставки!
                            </div>
                            <div className={"one_order_page_wrapper_for_all_status"}>

                                {
                                    allDeliveryMethods ?
                                        allDeliveryMethods.map((item, index) => {
                                            return (<div

                                                key={item.id}
                                                className={"one_item_to_change_wrapper"} >

                                                <div
                                                    onClick={e => changeDelivery(item)}
                                                    className={"one_item_to_change_status"}>
                                                    {item.name}
                                                    <span className={"no_switch_color"}>цель: {item.target}, значение: {item.target_value}</span>
                                                </div>

                                            </div>)
                                        }) : ""
                                }
                            </div>
                            <div className={"one_order_page_save_and_notification_line"}>
                                <span
                                     onClick={e => saveChangeDelivery(oneOrder.custom_order_id, oneOrder.delivery.id)}
                                    className={ saveDeliveryTriggerButton ? "save_change_status trig" : "save_change_status"}>Сохранить</span>

                                <span className={"notification_save_changer_line"}>{saveDeliveryNotification ? saveDeliveryNotification : ""}</span>

                            </div>

                            <div className={"horizontal_det_line"}>

                            </div>
                            <div className={"change_fields_on_delivery_container_wrap"}>
                                {
                                    oneOrder.delivery ?
                                        <div className={"container_for_change_delivery_fields"}>
                                            <span className={"span_name_data_order"}>Вы можете изменить следующие поля: </span>
                                            {
                                                oneOrder.delivery.delivery_type === "sdek_pvz" || oneOrder.delivery.delivery_type === "yandex_pvz" ?
                                                    <div className={"wrapper_delivery_change_for_fields"}>
                                                        <div className={"change_delivery_field_wrapper"}>
                                                            <span className={"change_delivery_field_wrapper_name_field"}>Код офиса ПВЗ</span>
                                                            <input
                                                                value={oneOrder.pvz_code ? oneOrder.pvz_code : ""}
                                                                onChange={e => changeField(e.target.value, "pvz_code")}
                                                                type="text"/>
                                                        </div>
                                                        <div className={"change_delivery_field_wrapper"}>
                                                            <span className={"change_delivery_field_wrapper_name_field"}>Postal code</span>
                                                            <input
                                                                value={oneOrder.pvz_postal ? oneOrder.pvz_postal : ""}
                                                                onChange={e => changeField(e.target.value, "pvz_postal")}

                                                                type="text"/>
                                                        </div>
                                                        <div className={"change_delivery_field_wrapper"}>
                                                            <span className={"change_delivery_field_wrapper_name_field"}>Адрес пункта ПВЗ</span>
                                                            <input
                                                                value={oneOrder.pvz_address ? oneOrder.pvz_address : ""}
                                                                onChange={e => changeField(e.target.value, "pvz_address")}
                                                                type="text"/>
                                                        </div>
                                                        <div className={"change_delivery_field_wrapper"}>
                                                            <span className={"change_delivery_field_wrapper_name_field"}>Сумма к оплате</span>
                                                            <input
                                                                value={oneOrder.result_price ? oneOrder.result_price : ""}
                                                                onChange={e => changeField(e.target.value, "result_price")}
                                                                type="text"/>
                                                        </div>
                                                        <div className={"change_delivery_field_wrapper"}>
                                                            <span className={"change_delivery_field_wrapper_name_field"}>Сумма за доставку</span>
                                                            <input
                                                                value={oneOrder.deliveryPrice ? oneOrder.deliveryPrice : ""}
                                                                onChange={e => changeField(e.target.value, "deliveryPrice")}
                                                                type="text"/>
                                                        </div>

                                                    </div>
                                                    :
                                                    <div className={"wrapper_delivery_change_for_fields"}>
                                                        <div className={"change_delivery_field_wrapper"}>
                                                            <span className={"change_delivery_field_wrapper_name_field"}>Адрес. Ул, дом, кв</span>
                                                            <input
                                                                value={oneOrder.orderAdressSearch ? oneOrder.orderAdressSearch : ""}
                                                                onChange={e => changeField(e.target.value, "orderAdressSearch")}
                                                                type="text"/>
                                                        </div>
                                                        <div className={"change_delivery_field_wrapper"}>
                                                            <span className={"change_delivery_field_wrapper_name_field"}>Этаж</span>
                                                            <input
                                                                value={oneOrder.floor ? oneOrder.floor : ""}
                                                                onChange={e => changeField(e.target.value, "floor")}
                                                                type="text"/>
                                                        </div>
                                                        <div className={"change_delivery_field_wrapper"}>
                                                            <span className={"change_delivery_field_wrapper_name_field"}>Подъезд</span>
                                                            <input
                                                                value={oneOrder.front_door ? oneOrder.front_door : ""}
                                                                onChange={e => changeField(e.target.value, "front_door")}
                                                                type="text"/>
                                                        </div>
                                                        <div className={"change_delivery_field_wrapper"}>
                                                            <span className={"change_delivery_field_wrapper_name_field"}>Домофон</span>
                                                            <input
                                                                value={oneOrder.intercom ? oneOrder.intercom : ""}
                                                                onChange={e => changeField(e.target.value, "intercom")}
                                                                type="text"/>
                                                        </div>
                                                        <div className={"change_delivery_field_wrapper"}>
                                                            <span className={"change_delivery_field_wrapper_name_field"}>Сумма к оплате</span>
                                                            <input
                                                                value={oneOrder.result_price ? oneOrder.result_price : ""}
                                                                onChange={e => changeField(e.target.value, "result_price")}
                                                                type="text"/>
                                                        </div>
                                                        <div className={"change_delivery_field_wrapper"}>
                                                            <span className={"change_delivery_field_wrapper_name_field"}>Сумма за доставку</span>
                                                            <input
                                                                value={oneOrder.deliveryPrice ? oneOrder.deliveryPrice : ""}
                                                                onChange={e => changeField(e.target.value, "deliveryPrice")}
                                                                type="text"/>
                                                            <div className={"reminder_order_fields"}>При изменении/добавлении суммы доставки, также необходимо отредактировать поле "Сумма к оплате" с учетом обновленной суммы доставки</div>
                                                        </div>
                                                    </div>
                                            }





                                            {/*////######################*/}
                                            <div className={"one_order_page_save_and_notification_line"}>
                                                <span
                                                    onClick={e => saveDeliveryAddressFields(oneOrder)}
                                                className={ saveDeliveryAddressFieldsTrigger ? "save_change_status trig" : "save_change_status"}>Сохранить</span>

                                                <span className={"notification_save_changer_line"}>{saveStatusAddressFields ? saveStatusAddressFields : ""}</span>

                                            </div>






                                        </div>
                                        : ""
                                }

                            </div>
                        </div>
                        <div className="one_order_page_ident_container">


                                    <div className="one_order_page_ident_container_top_line column">
                                        <div className="one_order_page_ident_container_top_line_item_left">
                                            Комментариев к заказу: { managerCommentsArr[0] ? managerCommentsArr.length : "0"  }
                                        </div>
                                        {managerCommentsArr[0] ?
                                            <span onClick={e => setOpenMenagerComments(!openManagerComments)}
                                                  className={"detail_comment_open_btn"}>{openManagerComments ? "Скрыть" : "Показать"}
                                             </span>
                                            : ""
                                        }

                                    </div>



                            <div className={"order_detail_comments_list"}>
                                {
                                    managerCommentsArr[0]  && openManagerComments ?
                                        managerCommentsArr.map((item,index) => {
                                            return(

                                                <div key={index} className={"one_comment_wrapper"}>
                                                    <div className={"detail_order_one_comment_dop_field_wrapper"}>
                                                        <span className={"detail_page_id_wrapper"}> id {item.id + "  "+ item.user_name}</span>

                                                        <span
                                                            onClick={e => deleteOneComment(index)}
                                                            className={"delete_comment_hand"}> удалить </span>
                                                    </div>
                                                    <span className={"detail_page_comment_text"}>{item.text}</span>
                                                    <span className={"detail_page_comment_time"}>{item.time}</span>
                                                </div>
                                            )
                                        })
                                        :""
                                }
                            </div>

                            <div className={"detail_order_page_add_new_comment"}>
                                <span className={"hey_you_can_add_new_comment"}>
                                    Оставьте свой комментарий
                                </span>
                                <textarea
                                    value={commentField}
                                    onChange={e => commentChangeHandler(e.target.value)}
                                    className={"add_comment_detail_order_input"} placeholder={"Ввести текст"} type="text"/>
                                <span
                                    onClick={e => addNewCommentHandler()}
                                    className={"save_comment_detail_order"}>Сохранить</span>
                            </div>

                        </div>

                        <div className="one_order_page_ident_container">
                            <div className="one_order_page_ident_container_top_line">
                                <div className="one_order_page_ident_container_top_line_item_left">
                                    Товары в заказе: {basketItemsForOneOrder ? basketItemsForOneOrder.length : 0 }
                                </div>
                                <div className="one_order_page_ident_container_top_line_item_right">
                                   <span
                                       onClick={e => setActiveAreaAddNewProduct(true)}
                                       className={"one_order_page_ident_add_new_product"}>Добавить товар</span>
                                </div>
                            </div>
                            <div className={activeAreaAddNewProduct ? "add_new_product_to_order_area active" : "add_new_product_to_order_area"}>
                                    <div className={"one_order_add_area_top_line"}>
                                        <div className={"one_order_add_area_top_line_notif"}>
                                            Поиск по названию товара выполнится автоматически, когда вы введете 3 и более символа.
                                        </div>

                                        <span
                                            onClick={e => setActiveAreaAddNewProduct(false)}
                                            className={"one_order_add_close_area"}> Скрыть </span>
                                    </div>
                                <div className={"one_order_add_area_search_block"}>
                                    <input
                                        placeholder={"Введите название товара"}
                                        value={searchValueNewProduct}
                                        onChange={e => setSearchValueNewProduct(e.target.value)}
                                        className={"one_order_this_search"} type="text"/>
                                </div>

                                <div className={"one_order_search_result_area"}>
                                    {
                                        searchProductsResult && !selectedOneProductInSearchArea ?

                                          <div className={"one_order_search_result_container"}>
                                              {
                                                  searchProductsResult.map((item,index) => {
                                                      return(
                                                          <div
                                                              onClick={e => searchAreaOneItemClickHandler(item) }
                                                              key={item.id} className={"one_search_item_for_add_area"}>
                                                              <div className={"one_item_search_id"}>{item.id}</div>
                                                              <div className={"one_item_search_img"}>
                                                                  <img src={process.env.REACT_APP_API_URL+"uploads/images/"+item.id+"/214_"+item.previev_photo} alt=""/>
                                                              </div>
                                                              <div className={"one_item_search_name"}> {item.name} </div>
                                                              <div className={"one_item_search_count"}> {"Торг. предложений: "+item.childProducts.length} </div>
                                                              <div>
                                                                  {/*searchAreaOneItemClickHandler*/}
                                                                  {/*<div></div>*/}
                                                                  {/*<div></div>*/}
                                                              </div>
                                                          </div>
                                                      )
                                                  })
                                              }
                                          </div>
                                            :
                                            <div className={"no_search_and_select_detail"}>
                                                {
                                                    selectedOneProductInSearchArea && selectedChild ?
                                                        <div className={"add_new_product_to_order_wrapper"}>
                                                            <span className={"one_item_config_title"}>Выберите цвет и размер товара, который хотите добавить</span>
                                                            <div className={"one_item_configurator"}>
                                                                <div className={"one_item_configurator_img_container"}>
                                                                    <img src={process.env.REACT_APP_API_URL+"uploads/images/"+selectedChild.product.id+"/"+selectedChild.id+"/214_"+selectedChild.img_preview} alt=""/>
                                                                </div>
                                                                <div className="one_item_detail_order_page_content">
                                                                    <span className={"span_for_one_product_order"}>
                                                                        {selectedChild.name}
                                                                    </span>
                                                                    <span className={"span_for_one_product_articul"}>
                                                                       Артикул: {selectedChild.product.articul}
                                                                    </span>
                                                                    <div className="wrapper_for_data_span">
                                                                        <div className="colors_container_one_order">
                                                                            <span className={"color_you_can_take_this"}>Доступные цвета: </span>
                                                                            {
                                                                                selectedOneProductInSearchArea.childProducts.map((item,index) => {
                                                                                    if(item.color.id ===  selectedChild.color.id) {
                                                                                        return(
                                                                                            <div
                                                                                                key={item.color.id}
                                                                                                style={item.color.img ? {backgroundImage: "url("+process.env.REACT_APP_API_URL+"uploads/images/colors/"+item.color.id+"/"+item.color.img+")"} : {backgroundColor: item.color.hex}} className={"one_order_color_check active"}>

                                                                                            </div>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                key={item.color.id}
                                                                                                onClick={e => switchSelectedChild(item)}
                                                                                                style={item.color.img ? {backgroundImage: "url("+process.env.REACT_APP_API_URL+"uploads/images/colors/"+item.color.id+"/"+item.color.img+")"} : {backgroundColor: item.color.hex}} className={"one_order_color_check"}>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>
                                                                        <span
                                                                            className="one_order_page_color_current_inform">Текущий цвет: {selectedChild.color.color_name}</span>
                                                                    </div>
                                                                    <div className="colors_container_one_order">
                                                                        <span className="color_you_can_take_this">Доступные размеры: </span>
                                                                        {
                                                                            selectedChild.child_product_size_field.map((item,index) => {
                                                                                return(
                                                                                    <div
                                                                                        key={item.size_name}
                                                                                        onClick={e => setSelectedCurrentSize(item.size_name)}
                                                                                        className={item.size_name === selectedCurrentSize ? "one_order_size_check active" : "one_order_size_check"}>
                                                                                        {item.size_name}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>


                                                                </div>

                                                                <div className="one_order_detail_one_product">
                                                                    <div className="one_order_detail_one_product_first">
                                                                        <div className="wrapper_for_counter">
                                                                            <span className="count_order_page_adm_text">Количество: </span>
                                                                            <MiniCountController
                                                                                count={selectedCount}
                                                                                decrease={e => changeSelectedCountHandler("min", selectedCount)}
                                                                                increase={e => changeSelectedCountHandler("max", selectedCount)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className={"one_order_detail_one_product_two"}>
                                                                            <span className={"nest_price"}>
                                                                                {
                                                                                    selectedChild.sell_price ?
                                                                                        priceToUserString(selectedChild.sell_price*selectedCount)+" ₽"
                                                                                        :
                                                                                        priceToUserString(selectedChild.product.price * selectedCount)+" ₽"
                                                                                }
                                                                            </span>
                                                                        <span className={"nest_sell_price"}>
                                                                            {
                                                                                selectedChild.sell_price ?
                                                                                    priceToUserString(selectedChild.product.price * selectedCount)+" ₽"
                                                                                    : ""
                                                                            }
                                                                        </span>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className={"add_new_item_to_order_configurator_panel"}>
                                                                <span className={"conf_panel_text"}>
                                                                    Внимание! после нажатия кнопки "Добавить выбранный товар", товар будет добавлен и страница перезагрузится.
                                                                    Предварительно сохраните все остальные измененные данные.
                                                                </span>
                                                                <div className={"conf_panel_controll_buttons"}>
                                                                        <span
                                                                            onClick={e => goToBack()}
                                                                            className={"btn_style_conf_panel"}>
                                                                            Назад
                                                                        </span>
                                                                        <span
                                                                            onClick={e => addSelectedItemToOrderBasket()}
                                                                            className={"btn_style_conf_panel"}>
                                                                            Добавить выбранный товар
                                                                        </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={"no_search_area_notif"}>
                                                            Введите запрос, чтобы вывести доступные товары.
                                                        </div>
                                                }

                                            </div>
                                    }

                                </div>
                            </div>
                            <div className={"order_controll_line"}>
                                <span
                                    onClick={e => saveChangesBasketItemsOrdedById(basketItemsForOneOrder,oneOrder.id)}
                                    className={"save_change_status"}> Сохранить изменения </span>
                            </div>








                            <div className={"detail_order_page_items_wrapper"}>
                                {basketItemsForOneOrder && othersChildProductsForOrder ? basketItemsForOneOrder.map((item,indexBasketItem) => {
                                    return(
                                        <div key={indexBasketItem} className={"one_item_detail_order_page"}>
                                            <div className={"one_item_detail_order_page_img"}>
                                                <img src={process.env.REACT_APP_API_URL+"uploads/images/order/214_"+item.item.img_preview} alt=""/>
                                            </div>
                                            <div className={"one_item_detail_order_page_content"}>
                                                    <span className={"span_for_one_product_order"}> {item.item.name} </span>
                                                    <span className={"span_for_one_product_articul"}>Артикул:  {item.item.product.articul} </span>

                                                {/*<div className={"wrapper_for_data_span"}>*/}
                                                {/*    <span className={"span_name_data_order"}>Количество: </span> <span*/}
                                                {/*    className={"span_value_data"}> Кол-во: {item.count} </span>*/}
                                                {/*</div>*/}

                                                <div className={"wrapper_for_data_span"}>
                                                    <div className={"colors_container_one_order"}>
                                                        <span className={"color_you_can_take_this"}>Доступные цвета: </span>
                                                        {
                                                            othersChildProductsForOrder.map((reservItem,reservIndex) => {

                                                                if(reservItem.productId === item.item.productId) {

                                                                    return  reservItem.product.childProducts.map((childProductReservItem, childProductReservIndex) => {

                                                                        if(childProductReservItem.color.id  === item.item.color.id) {
                                                                            return(
                                                                                <div style={item.item.color.img ? {backgroundImage: "url("+process.env.REACT_APP_API_URL+"uploads/images/colors/"+item.item.color.id+"/"+item.item.color.img+")"} : {backgroundColor: item.item.color.hex}} key={item.item.color.id}
                                                                                    // onClick={e => clickColorHandler(item) }
                                                                                     className={"one_order_color_check active"}>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div style={childProductReservItem.color.img ? {backgroundImage: "url("+process.env.REACT_APP_API_URL+"uploads/images/colors/"+childProductReservItem.color.id+"/"+childProductReservItem.color.img+")"} : {backgroundColor: childProductReservItem.color.hex}} key={childProductReservItem.color.id}
                                                                                    onClick={e => oneProductSwitchColor(childProductReservItem,indexBasketItem, reservItem) }
                                                                                     className={"one_order_color_check"}>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            })

                                                        }
                                                    </div>
                                                    <span className={"one_order_page_color_current_inform"}>Текущий цвет: {item.item.color.color_name}</span>
                                                </div>
                                                <div className={"wrapper_for_data_span"}>
                                                    <div className="colors_container_one_order">
                                                        <span className={"color_you_can_take_this"}>Доступные размеры: </span>

                                                        {
                                                            item.item.child_product_size_field.map((itemSize,index) => {
                                                                return(<div
                                                                        key={itemSize.size_name}
                                                                        onClick={e => changeSizeOneOrderItemHandler(itemSize.size_name,indexBasketItem)}
                                                                        className={itemSize.size_name === item.currentSize ? "one_order_size_check active" : "one_order_size_check"}>
                                                                        {itemSize.size_name}
                                                                    </div>
                                                                )
                                                            })
//
                                                        }
                                                    </div>
                                                </div>

                                                <Link className={"detail_order_page_link_to_product"} to={"/catalog/product/"+item.item.code+"/"} target={"_blank"}>перейти к товару</Link>


                                            </div>
                                            <div className={"one_order_detail_one_product"}>
                                                {
                                                    item.mbPerformance ?
                                                        <div className={"one_order_detail_one_mb_count"}>
                                                            {
                                                                Array.isArray(item.mbPerformance) ?
                                                                item.mbPerformance.map((mbItem, mbIndex) => {
                                                                    return (
                                                                        <div key={mbIndex} className={"one_order_detail_one_product_first_mb"}>
                                                                            <div className={"wrapper_for_counter"}>
                                                                                <span className={"count_order_page_adm_text"}>{mbIndex + 1} линия: </span>
                                                                                <MiniCountController
                                                                                    count={mbItem.quantity}
                                                                                    decrease={e => changeMbCountOneItem("min", indexBasketItem, mbIndex)}
                                                                                    increase={e => changeMbCountOneItem("max", indexBasketItem, mbIndex)}
                                                                                />
                                                                            </div>
                                                                            {
                                                                                item.mbPerformanceSeparatedFlag ?
                                                                                    <button
                                                                                        onClick={e => deleteOneMbItemBasket(indexBasketItem, mbIndex)}
                                                                                        className={"one_order_trash_one_line"}>
                                                                                        Удалить линию
                                                                                    </button>
                                                                                : ""
                                                                            }
                                                                            <span>{item.mbPerformance[mbIndex].discountedPriceOfLine + " ₽"}</span>
                                                                        </div>
                                                                    )
                                                                }) :
                                                                <div className={"one_order_detail_one_product_first_mb"}>
                                                                    <div className={"wrapper_for_counter"}>
                                                                        <span className={"count_order_page_adm_text"}>1 линия: </span>
                                                                        <MiniCountController
                                                                            count={item.mbPerformance.quantity}
                                                                            decrease={e => changeMbCountOneItem("min", indexBasketItem, 0)}
                                                                            increase={e => changeMbCountOneItem("max", indexBasketItem, 0)}
                                                                        />
                                                                    </div>
                                                                    <span>{item.mbPerformance.discountedPriceOfLine + " ₽"}</span>
                                                                </div>
                                                            }
                                                            <p>Общее количество товара: {item.count}</p>
                                                        </div>
                                                        : ""
                                                }
                                                <div className={"one_order_detail_one_product_two"}>
                                                    <span className={"nest_price"}>
                                                        {
                                                            item.item.sell_price ?
                                                                priceToUserString(item.item.sell_price*item.count)+" ₽"
                                                                :
                                                                priceToUserString(item.item.product.price * item.count)+" ₽"
                                                        }
                                                    </span>
                                                    <span className={"nest_sell_price"}>
                                                        {
                                                            item.item.sell_price ?
                                                                priceToUserString(item.item.product.price * item.count)+" ₽"
                                                                : ""
                                                        }
                                                    </span>
                                                    <div
                                                        onClick={e => deleteOneItemBasket(indexBasketItem)}
                                                        className={"one_order_trash_one"}>
                                                        <TrashBasket/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ""}
                            </div>










                        </div>

                    </div>
                    : ""
                }

            {/*
            {/*                <div className="top_order_information">*/}
            {/*                    {*/}
            {/*                        managerCommentsArr[0] ?*/}
            {/*                            <div>*/}
            {/*                                <span className={"detail_order_page_counter_comments"}>Комментариев к заказу: {managerCommentsArr.length}</span>*/}
            {/*                                <span*/}
            {/*                                    onClick={e => setOpenMenagerComments(!openManagerComments)}*/}
            {/*                                    className={"save_comment_detail_order"}>{openManagerComments ? "закрыть" : "показать"}</span>*/}
            {/*                            </div>*/}
            {/*                            : ""*/}
            {/*                    }*/}


            {/*                </div>*/}
            {/*                <div className="top_order_information">*/}
            {/*                    <span className={"detail_order_page_heading"}>Товары в заказе:</span>*/}
            {/*                    <div className={"detail_order_page_items_wrapper"}>*/}
            {/*                        {basketItemsForOneOrder ? basketItemsForOneOrder.map((item,index) => {*/}
            {/*                            return(*/}
            {/*                                <div key={index} className={"one_item_detail_order_page"}>*/}
            {/*                                        <div className={"one_item_detail_order_page_img"}>*/}
            {/*                                            <img src={process.env.REACT_APP_API_URL+"uploads/images/order/214_"+item.item.img_preview} alt=""/>*/}
            {/*                                        </div>*/}
            {/*                                        <div className={"one_item_detail_order_page_content"}>*/}
            {/*                                            <div className={"wrapper_for_data_span"}>*/}
            {/*                                                <span className={"span_name_data_order"}>Название: </span> <span*/}
            {/*                                                className={"span_value_data"}> {item.item.name} </span>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={"wrapper_for_data_span"}>*/}
            {/*                                                <span className={"span_name_data_order"}>Артикул: </span> <span*/}
            {/*                                                className={"span_value_data"}> {item.item.product.articul} </span>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={"wrapper_for_data_span"}>*/}
            {/*                                                <span className={"span_name_data_order"}>Количество: </span> <span*/}
            {/*                                                className={"span_value_data"}> Кол-во: {item.count} </span>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={"wrapper_for_data_span"}>*/}
            {/*                                                <span className={"span_name_data_order"}>Размер: </span> <span*/}
            {/*                                                className={"span_value_data"}> {item.currentSize} </span>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={"wrapper_for_data_span"}>*/}
            {/*                                                <span className={"span_name_data_order"}>Цвет: </span> <span*/}
            {/*                                                className={"span_value_data"}>  {item.item.color.color_name} </span>*/}
            {/*                                            </div>*/}
            {/*                                            <div className={"wrapper_for_data_span"}>*/}
            {/*                                                <span className={"span_name_data_order"}>Цена: </span>*/}
            {/*                                                {*/}
            {/*                                                    item.item.sell_price ?*/}
            {/*                                                        <div className={"wrapper_order_for_span_price"}>*/}
            {/*                                                            <span className={"span_value_data st_price"}>  {priceToUserString(item.item.sell_price)+" ₽"} </span>*/}
            {/*                                                            <span className={"span_value_data old_price"}>  {priceToUserString(item.item.product.price)+" ₽"} </span>*/}
            {/*                                                        </div>*/}
            {/*                                                        :*/}
            {/*                                                        <div>*/}
            {/*                                                            <span className={"span_value_data st_price"}>  {priceToUserString(item.item.product.price)+" ₽"} </span>*/}
            {/*                                                        </div>*/}
            {/*                                                }*/}

            {/*                                            </div>*/}
            {/*                                            <Link className={"detail_order_page_link_to_product"} to={"/catalog/product/"+item.item.product.id+"/"+item.item.id} target={"_blank"}>перейти к товару</Link>*/}


            {/*                                        </div>*/}
            {/*                                </div>*/}
            {/*                               )*/}
            {/*                        }) : ""}*/}
            {/*                    </div>*/}
            {/*                </div>*/}


            {/*            </div>*/}
            {/*            : ""*/}
            {/*    }*/}

            </div>
        </div>
    );
};
//
// <div className={"one_item_detail_order_page"} key={index}>
//     <div className={"one_line_one_item_support_container"}>
//         <div className={"one_line_one_item_support_container_inform"}>
//
//
//             <span>Цвет: {item.item.color.color_name}</span>
//             <span>Цена: {item.item.sell_price ? priceToUserString(item.item.sell_price)+" ₽ ": priceToUserString(item.item.product.price)+" ₽"}</span>
//         </div>
//     </div>
//
// </div>
export default OneOrderPage;
